import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const query = graphql`
  {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`

const SEO = ({ title, description }) => {
  const { site } = useStaticQuery(query)
  const metaDescription = description || site.siteMetadata.description
  return (
    <Helmet
      htmlAttributes={{ lang: "de" }}
      title={`${title} | Jetzt entdecken`}
      meta={[{ name: `description`, content: metaDescription }, {name: `facebook-domain-verification`, content: `jlv6s9sbismek8t12h5y7j81smhy14`}]}
    ></Helmet>
  )
}
export default SEO
